import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledThankYou = styled.section`
  padding: 60px 0;
  text-align: center;
  margin-bottom: 150px;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

	h2 {
		margin: 0 auto 2rem;
	}

  a {
    color: ${colors.orange};

    &:hover,
    &:active {
      color: ${colors.purpleDark};
    }
  }
`

const ThankYou = props => {
  return (
    <StyledThankYou>
      <Container data-aos="fade-up">
        <h2>Thank You</h2>
        {(props.first_name) ?
          <p>Thank you for completing the <b>Visby Medical Starter Kit</b> form, {props.first_name}. <br />Our team will be in touch with you shortly.</p>
          :
          <p>Thank you for completing the <b>Visby Medical Starter Kit</b> form. <br />Our team will be in touch with you shortly.</p>
        }
      </Container>
    </StyledThankYou>
  )
}

export default ThankYou


